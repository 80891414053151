<template>
    <el-dialog title="添加奖品" :visible="visible" width="50%" :close-on-press-escape="false" :close-on-click-modal="false"
        @close="close">
        <el-form ref="form" :model="info" size="medium" :rules="dataRule" :label-width="this.env.search_label_width">
            <el-row>
                <!-- 奖品名称 -->
                <el-col :span="14">
                    <el-form-item label="奖品名称" prop="gift_name">
                        <el-input v-model="info.gift_name" placeholder="请输入奖品名称"></el-input>
                    </el-form-item>
                </el-col>
                <!-- 奖品类型 -->
                <el-col :span="14">
                    <el-form-item label="奖品类型" prop="gift_type">
                        <el-select v-model="info.gift_type" style="width:100%" placeholder="请选择奖品类型">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <!-- 优惠券选择 -->
                <template v-if="info.gift_type === 2">
                    <el-col :span="14">
                        <el-form-item label="优惠券">
                            <el-select v-model="info.coupon_uuid" style="width:100%" placeholder="请选择优惠券">
                                <el-option v-for="item in couponList" :key="item.key_id" :label="item.name"
                                    :value="item.key_id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </template>
                <!-- 奖品图片 -->
                <el-col :span="14">
                    <el-form-item label="奖品图片" prop="gift_img">
                        <Qnupload v-model="info.gift_img" :sum="1" :fixedNumber="[1, 1]" />
                    </el-form-item>
                </el-col>
                <template v-if="info.gift_type !== 0">
                    <!-- 奖品说明 -->
                    <el-col :span="14">
                        <el-form-item label="奖品说明" prop="gift_note">
                            <el-input v-model="info.gift_note" type="textarea" maxlength="100" show-word-limit
                                rows="3"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- 奖品数量 -->
                    <el-col :span="14">
                        <el-form-item label="奖品数量" prop="gift_total">
                            <el-input-number v-model="info.gift_total" controls-position="right" width="80" size="small"
                                :min="1"></el-input-number> 个
                        </el-form-item>
                    </el-col>
                    <!-- 中奖概率 -->
                    <el-col :span="14">
                        <el-form-item label="中奖概率" prop="gift_prob">
                            <el-input-number v-model="info.gift_prob" controls-position="right" width="80" size="small"
                                placeholder="请输入中奖概率" :min="0" :max="100" :step="0.1"></el-input-number> %
                        </el-form-item>
                    </el-col>
                </template>
            </el-row>
            <el-row style="margin: 10px 0px">
                <el-button size="medium" type="primary" @click="save('form')">{{ type === 'add' ? '添 加' : '修 改'
                }}</el-button>
                <el-button size="medium" @click="close">取 消</el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import Qnupload from "@/components/Qnupload";
export default {
    components: { Qnupload },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        couponList: {
            type: Array,
            default: () => []
        },
        item: {
            type: Object,
            default: () => ({})
        },
        prizeIndex: {
            type: Number,
            default: 0
        },
        type: {
            type: String,
            default: 'add'
        }
    },
    watch: {
        item(newVal) {
            this.info = newVal;
        },
    },
    data() {
        return {
            info: {
                gift_name: '',
                coupon_uuid: '',
                gift_img: '',
                gift_note: '',
                gift_type: '',
                gift_total: 1,
                gift_prob: '',
                gift_floor: 0
            },
            options: [
                {
                    value: 1,
                    label: '商品'
                }, {
                    value: 2,
                    label: '优惠券'
                },
                // {
                //     value: 0,
                //     label: '未中奖'
                // }
            ],
            dataRule: {
                gift_name: [{ required: true, message: "奖品名称不能为空", trigger: "blur" }],
                gift_img: [{ required: true, message: "请上传奖品图片", trigger: "blur" }],
                gift_type: [{ required: true, message: "请选择奖品类型", trigger: "change" }],
                gift_note: [{ required: true, message: "奖品说明不能为空", trigger: "blur" }],
                gift_total: [{ required: true, message: "奖品数量不能为空", trigger: "blur" }],
                gift_prob: [{ required: true, message: "奖品中奖几率不能为空", trigger: "blur" }],
            },
        }
    },
    methods: {
        handleEmptyPrize(bool) {
            const { options } = this;
            let index = 0;
            for (let i in options) {
                if (options[i].value === 0) {
                    index = Number(i);
                }
            }
            if (bool === 1) {
                if (index === 0) {
                    options.push({
                        value: 0,
                        label: '未中奖'
                    });
                }
            } else {
                if (index !== 0) {
                    options.splice(index, 1);
                }
            }
            this.options = options;
        },
        save(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const { type, prizeIndex, info } = this;
                    if (type === 'add') {
                        this.$emit('onSubmit', info);
                    } else {
                        this.$emit('onSubmit', info, prizeIndex, 'edit');
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        close() {
            this.$emit('close');
        }
    }
}
</script>