<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/weapphome/lottery' }">转盘活动列表</el-breadcrumb-item>
                <el-breadcrumb-item>添加</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <!--内容-->
        <div class="page-content-x">
            <el-row>
                <el-col :span="17">
                    <el-form size="medium" v-loading.fullscreen.lock="loading" ref="form"
                        :label-width="this.env.label_width">
                        <el-col :span="24">
                            <el-form-item label="活动名称">
                                <el-input v-model="form.name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="活动对象">
                                <el-radio-group v-model="form.user_scope">
                                    <el-radio :label="0">普通用户</el-radio>
                                    <el-radio :label="1">新注册用户</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="背景图片">
                                <Qnupload v-model="form.background_img" :sum="1" :fixedNumber="[1, 1]" />
                            </el-form-item>
                        </el-col>

                        <el-col :span="24">
                            <el-form-item label="活动标题">
                                <Qnupload v-model="form.title_img" :sum="1" :fixedNumber="[1, 1]" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="按钮图片">
                                <Qnupload v-model="form.button_img" :sum="1" :fixedNumber="[1, 1]" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="分享图片">
                                <Qnupload v-model="form.share_img" :sum="1" :fixedNumber="[1, 1]" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="活动生效时间">
                                <el-date-picker v-model="showTime" type="datetimerange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="活动抽奖时间">
                                <el-date-picker v-model="gameTime" type="datetimerange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="参与方式">
                                <el-radio :value="form.total_type" :label="0" style="display: block;margin-bottom: 12px;"
                                    @input="changeTotalType">
                                    每人每天可抽{{ form.type_num }}次
                                </el-radio>
                                <el-radio :value="form.total_type" :label="1" @input="changeTotalType">每人总共可抽{{
                                    form.type_num }}次 </el-radio>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="参与次数">
                                <el-input-number v-model="form.type_num" controls-position="right" width="80" size="small"
                                    :min="1"></el-input-number> 次
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="分享增加次数">
                                <el-switch v-model="openShare" active-text="开启" inactive-text="关闭">
                                </el-switch>
                                <div v-if="openShare">
                                    每人分享后可额外获得
                                    <el-input-number v-model="form.share_num" controls-position="right" width="80"
                                        size="small" :min="1"></el-input-number> 次
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="分享规则">
                                <el-input v-model="form.share_rule" type="textarea" maxlength="2000" show-word-limit
                                    rows="3"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="未中奖提示语">
                                <el-input v-model="form.empty_msg" maxlength="12"
                                    placeholder="请输入未中奖提示语（最大12字符）"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="是否设置空奖">
                                <el-switch v-model="form.have_empty" active-text="开启" inactive-text="关闭" :active-value="1"
                                    :inactive-value="0" @change="haveEmptyChange">
                                </el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="奖品设置">
                                <el-button icon="el-icon-plus" size="medium" type="primary"
                                    @click="addPrize">添加奖品</el-button>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <!-- 奖品列表 -->
                            <el-form-item v-if="prizesData.length > 0" label="规格信息">
                                <section class="specs__table">
                                    <div class="specs__table-header">
                                        <section v-show="!form.have_empty" class="specs__table-title">
                                            <p>保底奖品</p>
                                        </section>
                                        <section class="specs__table-title">
                                            <p>奖品名称</p>
                                        </section>
                                        <section class="specs__table-title">
                                            <p>奖品类型</p>
                                        </section>
                                        <section class="specs__table-title">
                                            <p>图片</p>
                                        </section>
                                        <section class="specs__table-title">
                                            <p>奖品说明</p>
                                        </section>
                                        <section class="specs__table-title">
                                            <p>奖品数量</p>
                                        </section>
                                        <section class="specs__table-title">
                                            <p>中奖概率</p>
                                        </section>
                                        <section class="specs__table-title">
                                            <p>操作</p>
                                        </section>
                                    </div>
                                    <div class="specs__table-content">
                                        <section v-for="(item, index) in prizesData" :key="index">
                                            <div class="specs__table-cell">
                                                <div v-show="!form.have_empty" class="specs__table-title">
                                                    <div class="specs__radio"
                                                        :class="{ 'specs__radio--inner': item.gift_floor === 1 }"
                                                        @click="changeItemFloor(item, index)"></div>
                                                </div>
                                                <div class="specs__table-title">
                                                    <p>{{ item.gift_name }}</p>
                                                </div>
                                                <div class="specs__table-title">
                                                    <p>{{ item.gift_type === 1 ? '商品' : item.gift_type === 2 ? '优惠券' :
                                                        item.gift_type === 0 ? '未中奖' : '' }}
                                                    </p>
                                                </div>
                                                <div class="specs__table-title">
                                                    <el-image style="width: 22px; height: 22px; margin-right: 4px"
                                                        :src="item.gift_img" fit="contain"
                                                        :preview-src-list="[item.gift_img]">
                                                    </el-image>
                                                </div>
                                                <div class="specs__table-title">
                                                    <p v-if="item.gift_type === 0"> - </p>
                                                    <el-button v-else size="mini"
                                                        @click="seeDescription(item.gift_note)">查看说明</el-button>
                                                </div>
                                                <!-- 奖品数量 -->
                                                <div class="specs__table-title">
                                                    <p>{{ item.gift_type === 0 ? '-' : item.gift_total }}</p>
                                                </div>
                                                <!-- 奖品中奖几率 -->
                                                <div class="specs__table-title">
                                                    <p>{{ item.gift_prob }}%</p>
                                                </div>
                                                <div class="specs__table-title">
                                                    <el-link type="primary" :underline="false"
                                                        style="margin-right:4px;font-size: 12px;"
                                                        @click="editPrizeInfo(item, index)">编辑</el-link>
                                                    <el-link type="danger" :underline="false"
                                                        style="margin-right:4px;font-size: 12px;"
                                                        @click="deletePrize(index)">删除</el-link>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </section>
                            </el-form-item>
                        </el-col>

                        <el-col :span="24">
                            <el-form-item label="抽奖规则">
                                <el-input v-model="form.game_rule" type="textarea" maxlength="2000" show-word-limit
                                    rows="3"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item>
                                <el-button v-if="is_auth('POST:/api/oss/games/roulettes')" size="medium" type="primary"
                                    @click="save">保存
                                </el-button>
                                <el-button size="medium" @click="isreturn">返回</el-button>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-col>
            </el-row>
        </div>
        <PrizeEdit ref="prizeEdit" :item="prizeInfo" :prize-index="prizeIndex" :visible="prizeModalVisible"
            :type="prizeType" :coupon-list="couponList" @onSubmit="submitPize" @close="closePrize" />
    </div>
</template>
  
<script>
import Qnupload from "@/components/Qnupload";
import PrizeEdit from "./component/prizeEdit.vue";
import help from "./../../../custom/help";
export default {
    components: {
        Qnupload, PrizeEdit
    },
    data() {
        return {
            id: 0,
            loading: false,
            info: null,
            select_loading: false,
            store_list: [],
            store_uuid_all: [],
            operate_tag: [],
            chooseTag: [],
            tag_uuid_all: [],
            store_tag_list: [], // 门店标签
            gift_floor: 0,
            form: {
                name: '',
                user_scope: 0,
                background_img: [],
                title_img: [],
                button_img: [],
                total_type: 0,
                type_num: 1,
                share_num: 1,
                empty_msg: '',
                game_rule: '',
                share_rule: '',
                share_img: [],
                have_empty: 0
            },
            showTime: [],
            gameTime: [],
            motion_category_two: [],
            couponList: [],
            prizesData: [],
            prizeModalVisible: false,
            prizeInfo: {
                gift_name: '',
                coupon_uuid: '',
                gift_img: '',
                gift_note: '',
                gift_type: '',
                gift_total: 1,
                gift_prob: '',
            },
            prizeIndex: 0,
            openShare: false,
            prizeType: 'add',
            game_uuid: ''
        };
    },
    created() {
        this.getCoupon();
        this.init();
    },
    methods: {
        init() {
            const { id } = this.$route.query;
            if (id) {
                this.game_uuid = id;
                this.loading = true;
                this.getInfo();
            }
        },
        // 获取抽奖活动详情
        async getInfo() {
            this.loading = true;
            try {
                const res = await this.get(`games/roulette/${this.game_uuid}/profile`);
                this.loading = false;
                if (res.code === 0) {
                    this.form = res.data;
                    this.prizesData = res.data.gifts;
                    this.openShare = res.data.share_num > 0;
                    this.showTime = [help.timestampToTime(res.data.show_start_time, 'time'), help.timestampToTime(res.data.show_end_time, 'time')];
                    this.gameTime = [help.timestampToTime(res.data.game_start_time, 'time'), help.timestampToTime(res.data.game_end_time, 'time')];
                } else {
                    this.$message.warning(res.message);
                }
            } catch (error) {
                this.loading = false;
                this.$message.error(error);
            }
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth);
        },
        // 获取优惠券列表
        getCoupon() {
            let postdata = {
                api_name: "coupon.library.getavailablelist",
                token: this.Tool.get_l_cache("token"),
            };
            this.Tool.post_data("oss", postdata, (res) => {
                if (res.code === 0) {
                    this.couponList = res.data;
                }
            });
        },
        /**
         * 添加奖品
         */
        addPrize() {
            this.prizeModalVisible = true;
            this.prizeType = 'add';
        },
        seeDescription(info) {
            this.$alert(info, '奖品说明', {
                confirmButtonText: '确定',
            });
        },
        /**
         * 编辑奖品信息
         */
        editPrizeInfo(item, index) {
            this.prizeModalVisible = true;
            this.prizeInfo = help.deepClone(item);
            this.prizeIndex = index;
            this.prizeType = 'edit';
        },
        /**
         * 根据index删除数据
         */
        deletePrize(index) {
            this.prizesData.splice(index, 1);
            this.handleEmptyPrizeProb();
        },
        /**
         * 返回奖品信息
         */
        submitPize(item, index, type) {
            if (type === 'edit') {
                this.prizesData[index] = item;
            } else {
                this.prizesData.push(item);
            }
            this.prizeModalVisible = false;
            this.prizeIndex = 0;
            this.prizeInfo = {
                gift_name: '',
                coupon_uuid: '',
                gift_img: '',
                gift_note: '',
                gift_type: '',
                gift_total: 1,
                gift_prob: '',
            };
            this.handleEmptyPrizeProb();
        },
        /**
         * 处理计算未中奖概率
         */
        handleEmptyPrizeProb() {
            const { prizesData } = this;
            // 返回未中奖的个数
            const empty_number = help.returnArrayAdd(prizesData.map(item => item.gift_type === 0 ? 1 : 0));
            for (let i = 0; i < prizesData.length; i++) {
                const { gift_type } = prizesData[i];
                // 获取中奖奖品的概率总和
                const emptuyProb = 100 - help.returnArrayAdd(prizesData.map(item => {
                    if (item.gift_type !== 0) return item.gift_prob
                    else return 0
                }));
                // 给每个未中奖的概率赋值
                if (gift_type === 0) prizesData[i].gift_prob = Math.floor(emptuyProb / empty_number * 100) / 100;
            }
            this.prizesData = prizesData;
        },
        /**
         * 关闭设置奖品弹窗
         */
        closePrize() {
            this.prizeModalVisible = false;
            this.prizeInfo = {
                gift_name: '',
                coupon_uuid: '',
                gift_img: '',
                gift_note: '',
                gift_type: '',
                gift_total: 1,
                gift_prob: '',
                gift_floor: 0
            };
        },
        changeTotalType(e) {
            this.form.total_type = e;
        },
        /**
         * 设置活动是否有空奖
         * @param {*} e 
         */
        haveEmptyChange(e) {
            const { prizesData } = this;
            if (e === 1) {
                // 开启空奖
                for (let i in prizesData) {
                    prizesData[i].gift_floor = 0;
                }
            }
            this.prizesData = prizesData;
            this.$refs.prizeEdit.handleEmptyPrize(e);

        },
        changeItemFloor(_item, index) {
            const { prizesData } = this;
            for (let i in prizesData) {
                if (Number(i) === Number(index)) {
                    prizesData[i].gift_floor = 1;
                } else {
                    prizesData[i].gift_floor = 0;
                }
            }
            this.prizesData = prizesData;
        },
        /**
         * 保存数据
         */
        async save() {
            let postdata = {};
            const { form, showTime, gameTime, openShare } = this;
            if (showTime.length === 2) {
                form['show_start_time'] = help.dateToTimestamp(showTime[0]);
                form['show_end_time'] = help.dateToTimestamp(showTime[1]);
            }
            if (gameTime.length === 2) {
                form['game_start_time'] = help.dateToTimestamp(gameTime[0]);
                form['game_end_time'] = help.dateToTimestamp(gameTime[1]);
            }
            form['share_num'] = openShare ? form['share_num'] : 0;
            postdata = {
                ...form,
                gifts: this.prizesData,
            };
            let url = 'games/roulettes';
            if (this.game_uuid) {
                url = `games/roulette/${this.game_uuid}/profile`
            }
            try {
                const res = await this.post(url, postdata);
                if (res.code === 0) {
                    this.$message({
                        message: this.game_uuid ? "编辑成功" : '保存成功',
                        type: "success",
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.$router.push({ path: "/weapphome/lottery" });
                        },
                    });
                } else {
                    this.$message.warning(res.message);
                }
            } catch (error) {
                this.$message.error(error);
            }
        },
        isreturn() { this.$router.go(-1) },
    },
};
</script>
  
<style>
.specs__table {
    width: 100%;
    display: inline-block;

}

.specs__table p {
    padding: 0;
    margin: 0;
}

.specs__table-header {
    /* width: 100%; */
    height: 42px;
    background-color: #f5f7fa;
    border-top: 1px solid #ebeef5;
    border-bottom: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    display: flex;
    font-weight: 500;
}

.specs__table-title {
    flex: 1;
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-left: 1px solid #ebeef5;
}

.specs__table-title p {
    height: 100%;
    line-height: 42px;
}

.specs__table-content {
    width: 100%;
    font-size: 13px;
}

.specs__table-cell {
    display: flex;
    border-bottom: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
}
</style>